.testimonial-view-container {
  background: $dark-color-2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  color: #fff;

  // target div child with classname title-variant-1

  .title-variant-1 {
    margin: 10px 0px;
  }

  .testimonials-container {
    margin: 50px 25px 0px 25px;
    display: flex;
    gap: 60px;
  }
}

@media (max-width: $extra-large-screen) {
  .testimonial-view-container {
    .testimonials-container {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      width: 100%;
      .review-card {
        width: 100%;
        max-width: 80%;
      }
    }
  }
}

@media (max-width: $large-screen) {
  .testimonial-view-container {
    padding: 100px 20px;
    .review-card {
      max-width: 100%;
    }
  }
}

@media (max-width: $medium-screen) {
  .testimonial-view-container {
    .review-card {
      flex-direction: column;
      padding: 25px;
      text-align: center;
      max-height: auto;
      .review-card-author {
        border-right: none;
        margin-right: 0px;
        margin-bottom: 20px;
      }
      .review-card-quote-icon {
        display: none;
      }

      .review-card-title-container {
        justify-content: center;
        .review-card-title {
          margin-right: 0px;
        }
      }
    }
  }
}

@media (max-height: $small-height) {
  .testimonial-view-container {
    padding: 50px 20px;
  }
}
