html {
  font-size: $regular-font-size;
}

body {
  font-family: $secondary-font;
  overflow-x: hidden;
}

p {
  line-height: 1.5;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-25 {
  margin: 25px;
}

.color-grey {
  color: $grey;
}

.background-grey {
  background-color: #717171;
}

.background-black {
  background-color: #000;
}

.background-pink {
  background-color: #eb2252;
}

.white-text {
  color: #fff;
}

.light-gray-text {
  color: $light-gray-1;
}
.bold {
  font-weight: 700;
}

// general text styles

.gradient-text {
  background: $gradient-text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-regular {
  font-size: 22px;
}

.text-small {
  font-size: 16px;
}

.color-gray {
  color: #717171;
}

.title-1 {
  font-size: 3rem;
  font-weight: 700;
  // letter-spacing: 0.3rem;
}

.title-variant-1 {
  font-size: $large-font-size;
  font-family: $secondary-font;
  font-weight: 800;
  text-align: center;
}

.title-variant-2 {
  color: $primary-color;
  position: relative;
  display: flex;
  align-items: center;
}

.title-variant-2::before {
  content: "";
  display: block;
  // position: absolute;
  top: 42px;
  z-index: 2;
  height: 2px;
  width: 30px;
  margin-right: 15px;
  background: $primary-color;
}

.title-variant-2::after {
  content: "";
  display: block;
  // position: absolute;
  top: 42px;
  z-index: 2;
  height: 2px;
  width: 30px;
  margin-left: 15px;
  background: $primary-color;
}

.centered-underline {
  position: relative;
}
.centered-underline::after {
  content: "";
  display: block;
  // line should be the same width as the text
  position: absolute;
  bottom: -5px;
  z-index: -1;
  height: 4px;
  width: 60px;
  left: 0%;
  // transform: translateX(-50%);
  // background: $primary-color;
  // background: #fff;
}

.main-title {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  // background-color: green;
}

.non-selectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  user-select: none;
}

.transparent {
  opacity: 0%;
}

// .typed {
//   font-family: monospace;
//   display: inline-block;
//   font-weight: 500;
// }

// .typed:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: #000;
//   border-left: 2px solid #fff;
//   // don't go back
//   animation: animate 4s steps(13) infinite;
// }

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  40%,
  60% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@media (max-width: $large-screen) {
  .title-1 {
    font-size: 2.5rem;
  }
  .main-title {
    font-size: 3rem;
  }
  .title-variant-1 {
    font-size: $medium-large-font-size;
  }
}

@media (max-width: $medium-screen) {
  .main-title {
    font-size: 2rem;
  }
}

@media (max-width: $small-screen) {
  .text-regular {
    font-size: 18px;
  }
  .main-title {
    font-size: 1.5rem;
    flex-direction: column;
    align-items: center;
    // children
    > * {
      margin: 0px;
      padding: 0px;
    }
  }
}

@media (max-width: $extra-small-screen) {
  .main-title {
    font-size: 1.3rem;
  }
}
