.technologies-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-image: url("../../public/images/black-pattern.jpg");
  background-repeat: repeat;
}

.technologies-background-image {
  position: absolute;
  top: 0px;
  left: 0;
  min-width: 100%;
  min-height: 100vh;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.technologies-card-container {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

.technologies-sub-title {
  width: 900px;
  text-align: center;
  margin-top: 15px;
  color: $light-gray-1;
}

@media (max-width: $extra-extra-large-screen) {
  .technologies-card-container {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;

    .info-card-container {
      width: 100%;
    }
  }
  .technologies-view-container {
    height: auto;
    min-height: 1000px;
    padding: 0px 5%;
  }
}

@media (max-width: $medium-large-screen) {
  .technologies-view-container {
    // min-height: 1100px;
    padding: 100px 5%;
  }
  .technologies-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-height: $small-height) {
  .technologies-view-container {
    padding: 100px 5%;
  }
}
