.main-home-section-content {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}

.professions-list {
  display: inline-block;
  // padding-top: px;
  height: 81px;
  // background-color: red;
  overflow: hidden;
}

.profession-item {
  display: flex;
  animation: spin_words 25s infinite;
  align-items: center;
  height: 100%;
  // padding: 5px 0;
  // background-color: red;
}

.web-developer-w-space {
  letter-spacing: 13px;
}

.mobile-developer-w-space {
  letter-spacing: 6px;
}

.graphic-designer-w-space {
  letter-spacing: 7px;
}

@keyframes spin_words {
  // 1
  // go up
  2.5% {
    transform: translateY(-112%);
  }
  // settle down
  7.5% {
    transform: translateY(-100%);
  }
  // stay still for a while
  25% {
    transform: translateY(-100%);
  }

  // 2
  // go up
  27.5% {
    transform: translateY(-212%);
  }
  // settle down
  32.5% {
    transform: translateY(-200%);
  }
  // stay still for a while
  50% {
    transform: translateY(-200%);
  }

  // 3
  // go up
  52.5% {
    transform: translateY(-312%);
  }
  // settle down
  57.5% {
    transform: translateY(-300%);
  }
  // stay still for a while
  75% {
    transform: translateY(-300%);
  }

  // 4
  // go up
  77.5% {
    transform: translateY(-412%);
  }
  // settle down
  82.5% {
    transform: translateY(-400%);
  }
  100% {
    transform: translateY(-400%);
  }
  // stay still for a while
}

@media (max-width: $large-screen) {
  .web-developer-w-space {
    letter-spacing: 12.1px;
  }
  .graphic-designer-w-space {
    letter-spacing: 6.6px;
  }
  .mobile-developer-w-space {
    letter-spacing: 6.2px;
  }
}

@media (max-width: $medium-screen) {
  .web-developer-w-space {
    letter-spacing: 10.1px;
  }
  .graphic-designer-w-space {
    letter-spacing: 6.1px;
  }
  .mobile-developer-w-space {
    letter-spacing: 5.8px;
  }
}

@media (max-width: $small-screen) {
  .web-developer-w-space {
    letter-spacing: 9.2px;
  }
  .graphic-designer-w-space {
    letter-spacing: 5.82px;
  }
  .mobile-developer-w-space {
    letter-spacing: 5.65px;
  }
  .professions-list {
    height: 30px;
    margin-top: 10px;
  }
}

@media (max-width: $extra-small-screen) {
  .web-developer-w-space {
    letter-spacing: 8.45px;
  }
  .graphic-designer-w-space {
    letter-spacing: 5.5px;
  }
  .mobile-developer-w-space {
    letter-spacing: 5.4px;
  }
  .professions-list {
    height: 30px;
    margin-top: 10px;
  }
}
