// colors
$background-color: #1b1b1b;
$night-color: #090e11;
$primary-color: #eb2252;
$dark-color-1: #161717;
$dark-color-2: #090909;
$light-gray-1: #ccc;

// text------------------------------------------------

// text font
$primary-font-family: "Raleway", sans-serif;
$secondary-font: "Proxima Nova", sans-serif;
$regular-font-size: 16px;
$small-font-size: 18px;
$medium-large-font-size: 40px;
$large-font-size: 51px;
$extra-large-font-size: 60px;
$medium-font-size: 24px;
// text colors
$grey: #717171;
$gradient-text: linear-gradient(
  145deg,
  rgba(255, 0, 49, 0.94) 0%,
  rgba(224, 0, 254, 0.83) 100%
);

// screen widths

$extra-small-screen: 350px;
$small-screen: 500px;
$medium-small-screen: 600px;
$medium-screen: 750px;
$medium-large-screen: 870px;
$large-screen: 1000px;
$extra-large-screen: 1200px;
$extra-extra-large-screen: 1350px;

$small-height: 700px;
