.double-frame-card {
  width: 350px;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid $grey;
  text-align: center;
  position: relative;
  transition: all 0.3s ease-in-out;
  margin: 20px 40px 20px 40px;
}

.double-frame-card-second-frame {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid $grey;
  transition: all 0.3s ease-in-out;

  transform: rotate(4deg);
}

.double-frame-card-second-frame:hover {
  transform: rotate(0deg);
}

.double-frame-card-icon-container {
  display: flex;
  justify-content: center;
}
.double-frame-card-title {
  font-size: $medium-font-size;
  font-weight: bold;
  margin: 12px 0px;
}

.double-frame-card-icon {
  width: 80px;
}

.double-frame-card-text {
  color: $grey;
}
