.services-view-container {
  background: $dark-color-2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.services-title {
  font-size: 51px;
  font-family: $secondary-font;
  font-weight: 800;
  position: relative;
  text-align: center;
  //   text-stroke: 2px #000;
}

.hollow-text {
  color: transparent;
  -webkit-text-stroke: 1px $grey;
}

.hollow-text-light {
  color: transparent;
  -webkit-text-stroke: 1px $light-gray-1;
}

.white-hollow-text {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}

// tick under text
.primary-animatable-underline {
  display: inline-block;
  position: relative;
  z-index: 1;
  //   position: relative;
}
.primary-underline::before {
  content: "";
  display: block;
  // line should be the same width as the text
  position: absolute;
  top: 42px;
  z-index: -1;
  height: 22%;
  width: 100%;
  background: $primary-color;
}

.primary-animatable-underline::before {
  content: "";
  display: block;
  // line should be the same width as the text
  position: absolute;
  top: 42px;
  z-index: -1;
  height: 22%;
  width: 0%;
  background: $primary-color;
  transition: all 0.5s ease-in-out;
}

@keyframes draw-line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.primary-animatable-underline-show-0-6::before {
  animation-name: draw-line;
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}

.primary-animatable-underline-show-0-6-delayed::before {
  animation-name: draw-line;
  animation-duration: 0.5s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.services-sub-title {
  font-size: $regular-font-size;
  font-family: $secondary-font;
  text-align: center;
  width: 870px;
  color: $grey;
  margin-top: 20px;
  margin-bottom: 20px;
}

.services-card-container {
  margin-top: 3vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

// .slide-from-bottom {
//   animation-name: fade-in-from-bottom;
//   animation-duration: 0.6s;
//   animation-delay: 0.4s;
//   animation-fill-mode: forwards;
// }

.slide-from-top {
  animation-name: fade-in-from-top;
  animation-duration: 0.6s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}
@media (max-width: $large-screen) {
  .services-title {
    font-size: $medium-large-font-size;
    margin-bottom: 20px;
  }
  .services-sub-title {
    width: 90%;
  }

  .services-view-container {
    // min-height: 1600px;
    justify-content: flex-start;
    padding: 100px 5vw;

    .double-frame-card-icon {
      width: 70px;
      height: 70px;
    }
  }
}

@media (max-width: $medium-large-screen) {
  .services-view-container {
    .double-frame-card {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-height: $small-height) {
  .services-view-container {
    height: auto;
    // height should be bigger than children's height
    padding: 100px 5vw;
  }
}
