.home-main-view {
  height: 100vh;
  width: 100vw;
  background-color: $background-color;
  position: absolute;
  top: 0px;
  left: 0px;
}

.home-section-container {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; /* make scrollbar transparent */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* make scrollbar transparent */
  }
}

.home-section {
  flex: none;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #fff;
  // position: sticky;
  top: 0;
  scroll-snap-align: start;
}

.screen2 {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
}

// not my code

.container {
  height: 100vh;
  overflow-y: scroll;
}

.section {
  height: 100vh;
  position: relative;
  z-index: 1;
  font-size: $regular-font-size;
  transition: transform 0.3s ease-in-out;
}

.section.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transform: translate3d(0, 0, 0);
}

// a rhombus that takes up the whole screen on hover

// when .main-home-image-trigger is hovered, the .main-home-image-container will expand to fill the screen

.main-home-section {
  background-size: auto 100%;
  background-color: $dark-color-1;
  // position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#main-home-image-trigger {
  position: absolute;
  top: 50%;
  right: 190px;
  transform: translate(0%, -50%);
  clip-path: polygon(
    25% 0%,
    70% 0%,
    15% 54%,
    95% 35%,
    20% 100%,
    32% 51%,
    0% 55%
  );
  background-color: $primary-color;
  width: 25%;
  height: 80%;
  transition: all 0.5s ease-in-out;

  background-color: transparent;
  z-index: 4;
  // when .main-home-image-trigger is hovered, the .main-home-image-container will expand to fill the screen
  &:hover {
    + #main-home-image-container {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      // right: 0px;
      width: 100%;
      height: 100%;
      margin-right: 0px;
    }
    ~ .home-image {
      // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

#main-home-image-container {
  // position: absolute;
  // top: 50%;
  right: 190px;
  // clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(
    25% 0%,
    70% 0%,
    15% 54%,
    95% 35%,
    20% 100%,
    32% 51%,
    0% 55%
  );
  // border: 1px solid $primary-color;
  background-color: $night-color;
  margin-right: 190px;
  // background-color: $primary-color;
  width: 25%;
  height: 80%;
  // z-index: 1;
  transition: all 0.3s ease-in-out;
}

// #main-home-image-container::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: red;
//   z-index: -1;
// }

.home-image {
  // position non dependent on parent
  position: absolute;
  // top: 0;
  right: 220px;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.3s ease-in-out;
}

.copyright {
  position: absolute;
  z-index: 10;
  bottom: 17px;
  left: 30px;
  padding: 10px;
  font-size: 13px;
  color: $grey;
}

.section {
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 0.5s ease-in-out;
}

.hidden-section {
  transform: translateX(100%);
}

.section-2 {
  z-index: 1;
}

.section-3 {
  z-index: 2;
}

.section-4 {
  z-index: 3;
}

.section-5 {
  z-index: 5;
}

.section-6 {
  z-index: 6;
}

.show-section {
  transform: translateX(0);
}

.section-centent {
  height: 100%;
  width: 100%;
  padding: 120px 70px 0px 70px;
}

.sub-title-white {
  font-size: $regular-font-size;
  font-family: $secondary-font;
  text-align: center;
  color: $light-gray-1;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: $large-screen) {
  .home-section {
    height: auto;
    // min-height: 100vh;
  }
  .main-home-section {
    min-height: 100vh;
  }
  .copyright {
    display: none;
  }
  .section {
    position: relative;
    // undo top and left
    top: auto;
    left: auto;
  }
  .home-section-container {
    overflow: auto;
    height: auto;
  }
  .hidden-section {
    transform: translateX(0);
  }
}

@media (max-height: $small-height) {
  .section {
    position: relative;
    // undo top and left
    top: auto;
    left: auto;
  }
  .home-section-container {
    overflow: scroll;
  }
  .hidden-section {
    transition: transform 0s;
    transform: translateX(0);
  }
  .copyright {
    display: none;
  }
  .home-section {
    height: auto;
    // min-height: 100vh;
  }
  .main-home-section {
    min-height: 100vh;
  }
}
