.brand-card {
  border: 1px solid $light-gray-1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  cursor: pointer;
  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }
}
