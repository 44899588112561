.point-nav {
  position: absolute;
  bottom: 29px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.point-nav-item {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 35px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 5px;
}

.point-nav-item.active {
  background-color: $primary-color;
  width: 12px;
  height: 12px;
}

@media (max-width: $large-screen) {
  .point-nav {
    display: none;
  }
}

@media (max-height: $small-height) {
  .point-nav {
    display: none;
  }
}
