.about-me-view-container {
  background: #000;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1.7);
  }
  100% {
    transform: scale(1);
  }
}

.about-me-background-image {
  position: absolute;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  //  zoom animation

  transition: all 0.3s ease-in-out;

  //   left: -5%;
  animation: zoom 20s linear infinite;
  //   min-width: 110%;

  // add overlay
}

// .zoom-effect {
// }

.about-me-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-me-sub-title {
  margin-top: 40px;
  max-width: 870px;
}

.companies-logos-container {
  margin-top: 50px;
  // 4 elements per row
  display: flex;
  // add 10px margin between elements

  // & > * {
  //   margin: 0px 20px;
  // }

  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.counter-boxes-container {
  margin-top: 40px;
  // 4 elements per row
  display: flex;
  // add 10px margin between elements

  // & > * {
  //   margin: 0px 20px;
  // }

  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
}

@media (max-width: $extra-extra-large-screen) {
  .counter-boxes-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $large-screen) {
  .about-me-view-container {
    height: auto;
    min-height: 1000px;
  }
  .about-me-content {
    padding: 50px 5%;
    padding-right: 40px;
  }
}

@media (max-width: $medium-small-screen) {
  .counter-boxes-container {
    // display: grid;

    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    min-height: 1000px;
    .counter-box {
      width: 90vw;
    }
  }
  .about-me-view-container {
    min-height: 1500px;
  }
  .about-me-background-image {
    // min-height: 100%;
  }
}

@media (max-height: $small-height) {
  .about-me-view-container {
    min-height: 1500px;
  }
  .about-me-background-image {
    // min-height: 100%;
  }
}
