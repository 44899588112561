.contact-form {
  color: $grey;
  // 2 first elements on the same row and the last one on the next row
  // 3rd element should take the whole width of the row
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;
  grid-gap: 30px;
  // apply style to 3rth and 4th element

  & > *:nth-child(3) {
    grid-column: 1 / 3;
  }

  .contact-form-button-cotainer {
    margin-top: 10px;
    button {
      min-width: 108;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 46px;
    }
    .button-loader-container {
      height: 25px;
      width: 25px;
    }
  }
}

.contact-form-message {
  position: absolute;
  top: 20px;
  color: $light-gray-1;
  transition: all 0.3s ease-in-out;
}

.contact-form-message-container {
  position: relative;
  // overflow top should be hidden
}

@media (width: $large-screen) {
  .contact-form-message {
    top: 60px;
  }
}
