@keyframes fade-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes from-top {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-from-right {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes apear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-from-top {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden-animated-component-0-5 {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.delayed-animation-0-4 {
  animation-duration: 0.6s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-45 {
  animation-duration: 0.6s;
  animation-delay: 0.45s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-5 {
  animation-duration: 0.6s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-55 {
  animation-duration: 0.6s;
  animation-delay: 0.55s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-6 {
  animation-duration: 0.6s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-65 {
  animation-duration: 0.6s;
  animation-delay: 0.65s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-7 {
  animation-duration: 0.6s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-75 {
  animation-duration: 0.6s;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-8 {
  animation-duration: 0.6s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}

.delayed-animation-0-9 {
  animation-duration: 0.6s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
}

.slide-from-bottom {
  animation-name: fade-in-from-bottom;
}

.from-top {
  opacity: 0;
  transform: translateY(-20%);
  transition: all 0.5s ease-in-out;
}

.show {
  opacity: 1;
  transform: translate(0);
}

.slide-from-left {
  animation-name: fade-in-from-left;
}

.slide-from-right {
  animation-name: fade-in-from-right;
}

.apear-animation {
  animation-name: apear;
}

.float-on-hover {
  transition: all 0.5s ease-in-out;
}

.float-on-hover:hover {
  // make the background color darker
  background-color: rgba(0, 0, 0, 0.3);
}

.shine-from-left {
  position: relative;
}

.shine-from-left::before {
  content: "";
  position: absolute;
  background-color: red;
  background-color: rgba(255, 255, 255, 0.3);
  height: 100%;
  width: 40%;
  z-index: 2;
  left: -45%;
  border-radius: 50%;
  transform: skew(-30deg);
}

.shine-from-left:hover::before {
  animation: slide 1s ease-in-out;
}

@keyframes slide {
  0%,
  100% {
    left: -450%;
  }
  50% {
    left: 80%;
  }
}

@keyframes paint {
  0% {
    width: 0%;
    left: 0;
    right: auto;
  }

  30% {
    width: 100%;
    left: 0;
    right: auto;
  }
  60% {
    width: 100%;
    left: auto;
    right: 0;
  }
  100% {
    width: 0%;
    left: auto;
    right: 0;
  }
}

@keyframes show-text {
  0% {
    color: transparent;
  }
  100% {
    color: #fff !important;
  }
}

.paint-animation {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #fff;
  // delay
  transition: all 0s 1s;
  animation-duration: 0s;
}

.paint-animation::after {
  content: "";
  position: absolute;
  background-color: $primary-color;
  height: 100%;
  width: 0%;
  left: 0;
  z-index: 2;
  animation: paint 1s ease-out 0.4s;
  animation-delay: 0.4s;
}

.hidden-box {
  color: transparent;
}

.reveal-text-animation {
  position: relative;
  z-index: 0;
  overflow: hidden;
  animation-delay: 10s;
}

.reveal-text-animation::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $primary-color;
  transform: translateX(-101%);
  animation: tr-after 2s cubic-bezier(0.77, 0, 0.18, 1) 0s forwards;
}

.reveal-text-animation::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  // parents background color
  background-color: $dark-color-2;
  transform: translateX(0);
  animation: tr-before 2s cubic-bezier(0.77, 0, 0.18, 1) 0s forwards;
}

.before-after-animation-delay-0-5::after {
  animation-delay: 0.5s;
}

.before-after-animation-delay-0-5::before {
  animation-delay: 0.5s;
}

@keyframes tr-after {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(101%);
  }
}

@keyframes tr-before {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}
