.contact-view-container {
  background: $dark-color-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px 0px 20px;
  position: relative;
}

.contact-background-map {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 85%;
  z-index: 0;

  background-image: linear-gradient(
      to right,
      rgba(22, 23, 23, 1) 0%,
      rgba(22, 23, 23, 0.9) 20%,
      rgba(22, 23, 23, 0.75) 50%,
      rgba(22, 23, 23, 0.7) 60%,
      rgba(22, 23, 23, 0.7) 70%,
      rgba(22, 23, 23, 0.98) 100%
    ),
    url("../../public/images/paris-map.png");
}

.contact-view-content {
  z-index: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-view-upper-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8%;
}

.contact-view-left-section {
  max-width: 560px;
  padding: 0px 20px;
}

.contact-view-title {
  * {
    margin: 5px 0px;
    display: block;
  }

  margin-bottom: 20px;
}

.contact-info-container {
  margin-top: 80px;
  text-align: center;
  .contact-view-phone {
    font-size: $medium-font-size;
    font-weight: bold;
  }
  .contact-view-email {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
}

@media (max-width: $large-screen) {
  .contact-view-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .contact-view-upper-section {
    flex-direction: column;
    align-items: center;
    .contact-view-title {
      text-align: center;
      margin-bottom: 25px;
    }
    .contact-form {
      margin-top: 40px;
      width: 100%;
      max-width: 530px;
    }
    .contact-form-button-cotainer {
      grid-column: 1 / 3;
      button {
        margin-top: 20px;
        width: 100%;
      }
    }

    .light-gray-text {
      text-align: center;
    }
  }
}

@media (max-height: $small-height) {
  .contact-view-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
