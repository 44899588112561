// navbar styles
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 10;
  padding: 40px 55px 40px 75px;
}

.contact-details {
  display: flex;
  margin-left: 115px;
}

.contact-details .slash-separator {
  margin: 0px 25px;
  color: $grey;
}

.logo {
  font-size: $large-font-size;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: $large-screen) {
  .header {
    position: absolute;
  }
}

@media (max-width: $medium-screen) {
  .contact-details {
    display: none;
  }
  .header {
    padding: 36px 20px 40px 30px;
  }
  .logo {
    height: 30px;
  }
}
