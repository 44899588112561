.counter-box {
  height: 180px;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .counter-box-counter {
    font-size: $extra-large-font-size;
    font-weight: bold;
  }

  position: relative;
}

.counter-box::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  // blur
  //   filter: blur(10px);
  background: rgba(0, 0, 0, 0.4);
}
