.button-variant-1 {
  color: $light-gray-1;
  border: 1px solid $light-gray-1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}

.button-disabled {
  opacity: 0.5;
  cursor: auto;
}

.arrow-button {
  padding: 7px 25px;
  .button-arrow-icon {
    margin-left: 12px;
    height: 30px;
  }
}

// .button-variant-1::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border: 1px solid $light-gray-1;
//   // pivot 10 degrees
//   transform: rotate(4deg);
// }
