.info-card-container {
  display: flex;
  & > img {
    width: 50px;
    height: 50px;
    // object-fit:
    margin-right: 10px;
  }
  width: 400px;
}

.info-card-content {
  & > h3 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  & > p {
    color: $light-gray-1;
    margin-top: 7px;
  }
}
