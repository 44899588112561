.review-card {
  max-width: 600px;
  min-height: 280px;
  padding: 0px 20px;
  border: 1px solid $grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-card-author {
  min-width: 190px;
  display: flex;
  border-right: 1px solid $grey;
  margin-right: 27px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //   padding: 10px 0px;

  .review-card-avatar-container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
  }

  .review-card-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .review-card-author-name {
    font-weight: bold;
    margin-top: 10px;
  }

  .review-card-author-position {
    color: $grey;
    margin-top: 5px;
  }
}

.review-card-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .review-card-title {
    margin-right: 40px;
    font-weight: bold;
    font-size: $medium-font-size;
  }
}

.review-card-quote-icon {
  width: 40px;
}

.review-card-star-icon {
  width: 18px;
  margin-right: 7px;
}

.review-card-rating {
  margin-top: 17px;
}
