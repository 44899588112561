.hamburger-icon-line {
  width: 26px;
  height: 1px;
  display: inline-block;
  margin: 3.2px;
  transition: all 0.3s ease;
  // on hover
}

//  if hamburger-icon-line and hamburger-icon-line-short

.hamburger-icon-line-short {
  width: 16px;
}

.hamburger-icon {
  position: absolute;
  top: 59px;
  right: 50px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  // child elements
  & > * {
    background-color: #fff;
  }
  &:hover {
    & > * {
      background-color: #eb2252;
      width: 26px;
    }
  }
}

.active-hamburger-icon {
  & > *:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  & > *:nth-child(2) {
    opacity: 0;
  }
  & > *:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}

.hamburger-menu-background {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  // transition that apply when class name is removed
}

.open-hamburger-menu-background {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
}

.hamburger-menu {
  display: block;
  width: 380px;
  height: 100vh;
  background-color: #161616;
  position: fixed;
  z-index: 12;
  right: 0;
  transform: translateX(100%);
  // transition: all 0.5s ease;
}

.hamburger-menu-active {
  transform: translateX(0%);

  animation: slide-in-from-right 0.5s forwards;
}

@keyframes slide-in-from-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.hamburger-menu-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: $light-gray-1;
  padding: 120px 0 0 60px;
}

.hamburger-menu-list-item {
  font-size: $small-font-size;
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
  transition: all 0.2s ease;
}

@media (max-width: $small-screen) {
  .hamburger-menu {
    width: 100%;
    transition: all 0.5s ease;
  }
}
